<script>
import MenuForm from "@/views/menu/menu/menuForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    menuService: "menuService",
    visitorLevelService: "visitorLevelService"
  }
})
export default class MenuNew extends mixins(MenuForm) {
  formId = "edit-menu-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.menuService.create(this.model);
      this.$router.push("/menu/list");
      toastService.success(this.translations.success.menu_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.menus_new;
  }

  async afterCreate () {
    this.setVisitorLevels(await this.visitorLevelService.enabledLevels(this.model.id));
    this.model = {
      code: null,
      idEvent: null,
      visitorLevels: this.defaultVisitorLevelsValue()
    };
    this.isReady = true;
  }
}
</script>
